import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { images } from "@app/assets";

const tiers = [
  {
    title: "Experimente por 1 mês",
    price: "0",
    priceDescription: "primeiro mês",
    description: [
      "Customize sua turma",
      "Crie diversos tipos de exercícios",
      "Envio de notificações para alunos",
      "Bloqueio de aulas",
      "Adicione recursos para enriquecer suas aulas",
      "Alunos ilimitados",
    ],
    buttonText: "Comece a usar",
    buttonVariant: "outlined",
  },
  {
    title: "Anual",
    subheader: "Recomendado",
    price: "76,90",
    priceDescription: "/mês",
    description: [
      "Customize sua turma",
      "Crie diversos tipos de exercícios",
      "Envio de notificações para alunos",
      "Bloqueio de aulas",
      "Adicione recursos para enriquecer suas aulas",
      "Alunos ilimitados",
    ],
    buttonText: "Selecionar plano",
    buttonVariant: "contained",
  },
  {
    title: "Mensal",
    price: "89,90",
    priceDescription: "/mês",
    description: [
      "Customize sua turma",
      "Crie diversos tipos de exercícios",
      "Envio de notificações para alunos",
      "Bloqueio de aulas",
      "Adicione recursos para enriquecer suas aulas",
      "Alunos ilimitados",
    ],
    buttonText: "Selecionar plano",
    buttonVariant: "outlined",
  },
];

export default function Pricing() {
  return (
    <Box
      id="hero"
      sx={{
        width: "100%",
        backgroundImage: `url(${images.bgPattern})`,
        backgroundRepeat: "repeat",
      }}
    >
      <Container
        id="pricing"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" color="text.primary">
            Nossos Planos de Assinatura
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Transforme sua sala de aula com o Sharpii: a plataforma de idiomas
            feita para professores, com recursos poderosos e preços que cabem no
            seu orçamento. Assine agora e leve o ensino de idiomas para o
            próximo nível!
          </Typography>
        </Box>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  border: tier.title === "Anual" ? "1px solid" : undefined,
                  borderColor:
                    tier.title === "Anual" ? "primary.main" : undefined,
                  background:
                    tier.title === "Anual"
                      ? "linear-gradient(#033363, #021F3B)"
                      : undefined,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: tier.title === "Anual" ? "grey.100" : "",
                    }}
                  >
                    <Typography component="h3" variant="h6">
                      {tier.title}
                    </Typography>
                    {tier.title === "Anual" && (
                      <Chip
                        icon={<AutoAwesomeIcon />}
                        label={tier.subheader}
                        size="small"
                        sx={{
                          background: (theme) =>
                            theme.palette.mode === "light" ? "" : "none",
                          backgroundColor: "primary.contrastText",
                          "& .MuiChip-label": {
                            color: "primary.dark",
                          },
                          "& .MuiChip-icon": {
                            color: "primary.dark",
                          },
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      color: tier.title === "Anual" ? "grey.50" : undefined,
                    }}
                  >
                    <Typography component="h3" variant="h2">
                      ${tier.price}
                    </Typography>
                    <Typography component="h3" variant="h6">
                      &nbsp; {tier.priceDescription}
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      my: 2,
                      opacity: 0.2,
                      borderColor: "grey.500",
                    }}
                  />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: "flex",
                        gap: 1.5,
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          width: 20,
                          color:
                            tier.title === "Anual" ? "#ffdf00" : "primary.main",
                        }}
                      />
                      <Typography
                        component="text"
                        variant="subtitle2"
                        sx={{
                          color:
                            tier.title === "Anual" ? "grey.200" : undefined,
                        }}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    sx={{
                      bgcolor:
                        tier.title === "Anual" ? "#ffdf00" : "primary.main",
                      color: tier.title === "Anual" ? "black" : "white",
                    }}
                    fullWidth
                    variant={tier.buttonVariant as "outlined" | "contained"}
                    component="a"
                    href="https://admin.sharpii.com/signup"
                    target="_blank"
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
