import * as React from "react";
import Box from "@mui/material/Box";
import { Card, Container, Grid, Stack, Typography } from "@mui/material/";
import LockIcon from "@mui/icons-material/Lock";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: "Customize sua turma",
    description:
      "Com o Sharpii, os professores têm total controle para personalizar suas turmas, até mesmo escolhendo a cor que melhor representa cada grupo, tornando o ensino de idiomas mais envolvente e visualmente atraente.",
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: "Crie diversos tipos de exercícios",
    description:
      "Com o Sharpii, os professores têm a liberdade de criar diversos tipos de exercícios, proporcionando uma experiência de aprendizado de idiomas dinâmica e adaptada às necessidades individuais de cada aluno.",
  },
  {
    icon: <NotificationsActiveIcon />,
    title: "Envio de notificações para alunos",
    description:
      "Com o Sharpii, cada vez que o professor criar uma nova aula, os alunos serão imediatamente notificados, garantindo que ninguém perca nenhuma oportunidade de aprendizado.",
  },
  {
    icon: <LockIcon />,
    title: "Bloqueio de aulas",
    description:
      "Com o Sharpii, os professores podem bloquear aulas por data, organizando o cronograma de forma eficiente e garantindo que os alunos estejam sempre atualizados com os conteúdos mais recentes.",
  },
  {
    icon: <PermMediaIcon />,
    title: "Adicionar recursos",
    description:
      "Com o Sharpii, os professores podem enriquecer suas aulas adicionando recursos como PDFs, áudios e vídeos, criando uma experiência de aprendizado de idiomas mais completa e envolvente.",
  },
  {
    icon: <PersonPinIcon />,
    title: "Gere perguntas com nossa IA",
    description:
      "Com o Sharpii, os professores podem gerar exercícios com a ajuda de uma IA, facilitando a criação de atividades personalizadas e eficazes para o aprendizado de idiomas",
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#6700c1",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Oferecemos tudo o que é necessário para impulsionar suas aulas.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#ffdf00", fontWeight: 900 }}
          >
            Funcionalidades
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "#ffdf00",
                }}
              >
                <Box sx={{ color: "black" }}>{item.icon}</Box>
                <div>
                  <Typography
                    fontWeight="medium"
                    gutterBottom
                    sx={{ color: "black", fontWeight: 900 }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
