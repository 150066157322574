import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "../features/Home";
import PrivacyPolicy from "../features/PrivacyPolicy";

const RoutesConfig = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  </BrowserRouter>
);

export default RoutesConfig;
