import React from "react";
import Routes from "@app/router";

const App = () => (
  <>
    <Routes />
  </>
);

export default App;
