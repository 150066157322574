import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Markdown from "./MarkDown";

import privacyPolicyMD from "./privacy-policy";

const PrivacyPolicy = () => {
  console.log(privacyPolicyMD);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Markdown text={privacyPolicyMD} />
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
