const markdown = `
# Política de Privacidade

**Última atualização: 24 de maio de 2024**

Esta Política de Privacidade descreve nossas políticas e procedimentos sobre a coleta, uso e divulgação de suas informações quando você usa o Serviço e informa sobre seus direitos de privacidade e como a lei o protege.

Usamos seus dados pessoais para fornecer e melhorar o Serviço. Ao usar o Serviço, você concorda com a coleta e uso de informações de acordo com esta Política de Privacidade.

## Interpretação e Definições

### Interpretação

As palavras cujo a letra inicial é maiúscula têm significados definidos nas seguintes condições. As seguintes definições terão o mesmo significado, independentemente de aparecerem no singular ou no plural.

### Definições

Para os fins desta Política de Privacidade:

- **Conta** significa uma conta única criada para você acessar nosso Serviço ou partes dele.
- **Afiliado** significa uma entidade que controla, é controlada por, ou está sob controle comum com uma parte, onde "controle" significa propriedade de 50% ou mais das ações, participação acionária ou outros títulos com direito a voto para eleição de diretores ou outra autoridade de gestão.
- **Aplicativo** refere-se ao Sharpii, o programa de software fornecido pela Empresa.
- **Empresa** (referida como "a Empresa", "nós", "nos" ou "nosso" neste Acordo) refere-se à Purple Tecnologia da Informação LTDA, Santa Mônica, Belo Horizonte, Brasil.
- **País** refere-se ao Brasil.
- **Dispositivo** significa qualquer dispositivo que possa acessar o Serviço, como um computador, celular ou tablet digital.
- **Dados Pessoais** são quaisquer informações que se relacionam a um indivíduo identificado ou identificável.
- **Serviço** refere-se ao Aplicativo.
- **Fornecedor de Serviços** significa qualquer pessoa física ou jurídica que processa os dados em nome da Empresa. Refere-se a empresas ou indivíduos terceirizados empregados pela Empresa para facilitar o Serviço, fornecer o Serviço em nome da Empresa, realizar serviços relacionados ao Serviço ou auxiliar a Empresa na análise de como o Serviço é utilizado.
- **Dados de Uso** refere-se a dados coletados automaticamente, gerados pelo uso do Serviço ou da própria infraestrutura do Serviço (por exemplo, a duração de uma visita à página).
- **Você** significa o indivíduo acessando ou usando o Serviço, ou a empresa, ou outra entidade legal em nome da qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável.

## Coleta e Uso de Seus Dados Pessoais

### Tipos de Dados Coletados

#### Dados Pessoais

Ao usar nosso Serviço, podemos solicitar que você nos forneça certas informações de identificação pessoal que podem ser usadas para contatá-lo ou identificá-lo. Informações de identificação pessoal podem incluir, mas não estão limitadas a:

- Endereço de e-mail
- Nome e sobrenome
- Dados de Uso

#### Dados de Uso

Os Dados de Uso são coletados automaticamente ao usar o Serviço.

Os Dados de Uso podem incluir informações como o endereço de Protocolo de Internet (IP) do seu Dispositivo, tipo de navegador, versão do navegador, as páginas do nosso Serviço que você visita, a hora e a data da sua visita, o tempo gasto nessas páginas, identificadores exclusivos de dispositivos e outros dados de diagnóstico.

Quando você acessa o Serviço por meio de um dispositivo móvel, podemos coletar certas informações automaticamente, incluindo, mas não se limitando ao tipo de dispositivo móvel que você usa, sua ID exclusiva do dispositivo móvel, o endereço IP do seu dispositivo móvel, seu sistema operacional móvel, o tipo de navegador de Internet móvel que você usa, identificadores exclusivos de dispositivos e outros dados de diagnóstico.

Também podemos coletar informações que seu navegador envia sempre que você visita nosso Serviço ou quando você acessa o Serviço por meio de um dispositivo móvel.

### Informações Coletadas ao Usar o Aplicativo

Ao usar nosso Aplicativo, para fornecer funcionalidades do nosso Aplicativo, podemos coletar, com sua permissão prévia:

- Imagens e outras informações da câmera e da biblioteca de fotos do seu Dispositivo

Usamos essas informações para fornecer funcionalidades do nosso Serviço, para melhorar e personalizar nosso Serviço. As informações podem ser carregadas para os servidores da Empresa e/ou para o servidor de um Fornecedor de Serviços ou podem ser simplesmente armazenadas no seu dispositivo.

Você pode habilitar ou desabilitar o acesso a essas informações a qualquer momento, através das configurações do seu Dispositivo.

### Uso de Seus Dados Pessoais

A Empresa pode usar Dados Pessoais para as seguintes finalidades:

- Para fornecer e manter nosso Serviço, incluindo para monitorar o uso do nosso Serviço.
- Para gerenciar sua Conta: para gerenciar seu registro como usuário do Serviço. Os Dados Pessoais que você fornece podem dar a você acesso a diferentes funcionalidades do Serviço que estão disponíveis para você como um usuário registrado.
- Para a execução de um contrato: o desenvolvimento, cumprimento e execução do contrato de compra dos produtos, itens ou serviços que você adquiriu ou de qualquer outro contrato com nós através do Serviço.
- Para contatá-lo: Para contatá-lo por e-mail, chamadas telefônicas, SMS, ou outras formas equivalentes de comunicação eletrônica, como notificações push de um aplicativo móvel sobre atualizações ou comunicações informativas relacionadas às funcionalidades, produtos ou serviços contratados, incluindo as atualizações de segurança, quando necessário ou razoável para sua implementação.
- Para fornecer a você notícias, ofertas especiais e informações gerais sobre outros bens, serviços e eventos que oferecemos que são semelhantes aos que você já comprou ou perguntou sobre, a menos que você tenha optado por não receber tais informações.
- Para gerenciar seus pedidos: Para atender e gerenciar seus pedidos para nós.
- Para transferências comerciais: Podemos usar suas informações para avaliar ou conduzir uma fusão, desinvestimento, reestruturação, reorganização, dissolução ou outra venda ou transferência de alguns ou todos os nossos ativos, seja como uma preocupação contínua ou como parte de falência, liquidação ou processo semelhante, em que os Dados Pessoais mantidos por nós sobre nossos usuários do Serviço estão entre os ativos transferidos.
- Para outros fins: Podemos usar suas informações para outros fins, como análise de dados, identificação de tendências de uso, determinação da eficácia de nossas campanhas promocionais e para avaliar e melhorar nosso Serviço, produtos, serviços, marketing e sua experiência.

### Compartilhamento de Suas Informações Pessoais

Podemos compartilhar suas informações pessoais nas seguintes situações:

- Com Fornecedores de Serviços: Podemos compartilhar suas informações pessoais com Fornecedores de Serviços para monitorar e analisar o uso do nosso Serviço, para contatá-lo.
- Para transferências comerciais: Podemos compartilhar ou transferir suas informações pessoais em conexão com, ou durante negociações de, qualquer fusão, venda de ativos da Empresa, financiamento ou aquisição de todo ou parte do nosso negócio para outra empresa.
- Com Afiliados: Podemos compartilhar suas informações com nossos afiliados, caso em que exigiremos que esses afiliados honrem esta Política de Privacidade. Afiliados incluem nossa empresa-mãe e quaisquer outras subsidiárias, parceiros de joint venture ou outras empresas que controlamos ou que estão sob controle comum conosco.
- Com parceiros comerciais: Podemos compartilhar suas informações com nossos parceiros comerciais para oferecer a você certos produtos, serviços ou promoções.
- Com outros usuários: quando você compartilha informações pessoais ou interage de outra forma nas áreas públicas com outros usuários, tais informações podem ser vistas por todos os usuários e podem ser distribuídas publicamente fora.
- Com o seu consentimento: Podemos divulgar suas informações pessoais para qualquer outra finalidade com o seu consentimento.

### Retenção de Seus Dados Pessoais

A Empresa reterá seus Dados Pessoais apenas pelo tempo necessário para as finalidades estabelecidas nesta Política de Privacidade. Reteremos e usaremos seus Dados Pessoais na medida necessária para cumprir nossas obrigações legais (por exemplo, se formos obrigados a reter seus dados para cumprir com as leis aplicáveis), resolver disputas e fazer cumprir nossos acordos e políticas legais.

A Empresa também reterá Dados de Uso para fins de análise interna. Os Dados de Uso geralmente são retidos por um período de tempo mais curto, exceto quando esses dados são usados para fortalecer a segurança ou para melhorar a funcionalidade do nosso Serviço, ou quando somos legalmente obrigados a reter esses dados por períodos mais longos.

### Transferência de Seus Dados Pessoais

Suas informações, incluindo Dados Pessoais, são processadas nos escritórios operacionais da Empresa e em quaisquer outros lugares onde as partes envolvidas no processamento estão localizadas. Isso significa que essas informações podem ser transferidas para - e mantidas em - computadores localizados fora do seu estado, província, país ou outra jurisdição governamental onde as leis de proteção de dados podem ser diferentes das de sua jurisdição.

Seu consentimento para esta Política de Privacidade seguido pelo envio de tais informações representa seu acordo para essa transferência.

A Empresa tomará todas as medidas razoavelmente necessárias para garantir que seus dados sejam tratados com segurança e de acordo com esta Política de Privacidade e nenhuma transferência de seus Dados Pessoais ocorrerá para uma organização ou país, a menos que existam controles adequados em vigor, incluindo a segurança de seus dados e outras informações pessoais.

### Exclusão de Seus Dados Pessoais

Você tem o direito de excluir ou solicitar que ajudemos na exclusão dos Dados Pessoais que coletamos sobre você.

Nosso Serviço pode dar a você a capacidade de excluir certas informações sobre você dentro do Serviço.

Você pode atualizar, alterar ou excluir suas informações a qualquer momento, entrando em sua Conta, se tiver uma, e visitando a seção de configurações da conta que permite gerenciar suas informações pessoais. Você também pode entrar em contato conosco para

solicitar acesso, corrigir ou excluir qualquer informação pessoal que você nos tenha fornecido.

Observe, no entanto, que podemos precisar reter certas informações quando tivermos uma obrigação legal ou base legal para fazê-lo.

### Divulgação de Seus Dados Pessoais

#### Transações Comerciais

Se a Empresa estiver envolvida em uma fusão, aquisição ou venda de ativos, seus Dados Pessoais podem ser transferidos. Forneceremos aviso antes que seus Dados Pessoais sejam transferidos e se tornem sujeitos a uma Política de Privacidade diferente.

#### Aplicação da Lei

Em determinadas circunstâncias, a Empresa pode ser obrigada a divulgar seus Dados Pessoais se exigido por lei ou em resposta a solicitações válidas por autoridades públicas (por exemplo, um tribunal ou uma agência governamental).

#### Outros Requisitos Legais

A Empresa pode divulgar seus Dados Pessoais na crença de boa fé de que tal ação é necessária para:

- Cumprir uma obrigação legal
- Proteger e defender os direitos ou propriedade da Empresa
- Prevenir ou investigar possíveis irregularidades em conexão com o Serviço
- Proteger a segurança pessoal dos usuários do Serviço ou do público
- Proteger contra responsabilidade legal

### Segurança de Seus Dados Pessoais

A segurança de seus Dados Pessoais é importante para nós, mas lembre-se de que nenhum método de transmissão pela Internet, ou método de armazenamento eletrônico é 100% seguro. Enquanto nos esforçamos para usar meios comercialmente aceitáveis para proteger seus Dados Pessoais, não podemos garantir sua segurança absoluta.

### Privacidade das Crianças

Nosso Serviço não se dirige a menores de 13 anos. Não coletamos intencionalmente informações de identificação pessoal de menores de 13 anos. Se você é pai ou responsável e está ciente de que seu filho nos forneceu Dados Pessoais, entre em contato conosco. Se ficarmos cientes de que coletamos Dados Pessoais de menores de 13 anos sem verificação do consentimento dos pais, tomaremos medidas para remover essas informações de nossos servidores.

Se precisarmos confiar no consentimento como base legal para processar suas informações e seu país exigir o consentimento de um pai, podemos exigir o consentimento de seu pai antes de coletarmos e usarmos essas informações.

### Links para Outros Sites

Nosso Serviço pode conter links para outros sites que não são operados por nós. Se você clicar em um link de terceiros, será direcionado para o site desse terceiro. Aconselhamos fortemente que você revise a Política de Privacidade de cada site que visitar.

Não temos controle e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou práticas de sites ou serviços de terceiros.

### Alterações a esta Política de Privacidade

Podemos atualizar nossa Política de Privacidade de tempos em tempos. Notificaremos você sobre quaisquer alterações publicando a nova Política de Privacidade nesta página.

Avisaremos você por e-mail e/ou um aviso em destaque em nosso Serviço, antes da alteração se tornar efetiva e atualizaremos a data de "Última atualização" no topo desta Política de Privacidade.

Aconselhamos que você revise esta Política de Privacidade periodicamente para quaisquer alterações. Alterações a esta Política de Privacidade são eficazes quando publicadas nesta página.

### Contate-nos

Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco:

- Por e-mail: support@sharpii.com
`;

export default markdown;
