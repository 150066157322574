import * as React from "react";

import { Divider, Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";

import AppAppBar from "../../components/AppAppBar";
import Hero from "../../components/Hero";
// import LogoCollection from "./components/LogoCollection";
import Highlights from "../../components/Highlights";
import Pricing from "../../components/Pricing";
import Features from "../../components/Features";
// import Testimonials from "./components/Testimonials";
// import FAQ from "./components/FAQ";
import Footer from "../../components/Footer";
// import getLPTheme from "./getLPTheme";

// interface ToggleCustomThemeProps {
//   showCustomTheme: Boolean;
//   toggleCustomTheme: () => void;
// }

// function ToggleCustomTheme({
//   showCustomTheme,
//   toggleCustomTheme,
// }: ToggleCustomThemeProps) {
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         width: "100dvw",
//         position: "fixed",
//         bottom: 24,
//       }}
//     >
//       <ToggleButtonGroup
//         color="primary"
//         exclusive
//         value={showCustomTheme}
//         onChange={toggleCustomTheme}
//         aria-label="Platform"
//         sx={{
//           backgroundColor: "background.default",
//           "& .Mui-selected": {
//             pointerEvents: "none",
//           },
//         }}
//       >
//         <ToggleButton value>
//           <AutoAwesomeRoundedIcon sx={{ fontSize: "20px", mr: 1 }} />
//           Custom theme
//         </ToggleButton>
//         <ToggleButton value={false}>Material Design 2</ToggleButton>
//       </ToggleButtonGroup>
//     </Box>
//   );
// }

const Home = () => {
  // const [mode] = React.useState<PaletteMode>("light");
  // const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  // const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#6700c1",
      },
      secondary: {
        main: "#2096f3",
      },
      mode: "light",
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar />
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        {/* <LogoCollection /> */}
        <Highlights />
        <Divider />
        {/* <Testimonials />
        <Divider /> */}
        <Features />
        <Divider />
        <Pricing />
        <Divider />
        {/* <FAQ />
        <Divider /> */}
        <Footer />
      </Box>
    </ThemeProvider>
  );
};
export default Home;
