const logoWhite = require("./images/logo-white.png");
const logoColor = require("./images/logo-color.png");

const letteringCropDark = require("@app/assets/images/lettering-color-crop.png");
const letteringCropWhite = require("@app/assets/images/lettering-white-crop.png");

const traditional = require("@app/assets/images/questions-type/traditional.png");
const order = require("@app/assets/images/questions-type/order.png");
const speaking = require("@app/assets/images/questions-type/speaking.png");
const multipleChoice = require("@app/assets/images/questions-type/multiple-choice.png");

const bgPattern = require("@app/assets/images/bg-pattern.png");

const images = {
  letteringCropDark,
  letteringCropWhite,
  logoColor,
  logoWhite,
  bgPattern,
  traditional,
  order,
  speaking,
  multipleChoice,
};

export { images };
